import React, { useState, useEffect, useContext } from 'react';
import { AppState, store } from 'provider/GlobalStateProvider';
import { Button, FormInput, Notice, H6 } from '@snsw/react-component-library';
import {
  Svg,
  formMessages,
  validation,
  Spacer,
  UserSession,
  DisableTelOnTabletDesktop,
  EmailConfirmationModal,
} from '@rmstransactions/components';
import { InlineWrapper } from 'components/Styled/InlineWrapper';
import { sendEmail, SendEmailRequest } from 'services/api-services';
import { interimLicenceErrorStatus } from 'services/common-service';
import { envConfig } from 'config/env';
import styled from 'styled-components';

const EmailFormWrapper = styled.div`
  .checkbox-item label {
    cursor: pointer;

    &::before {
      top: 0;
    }
  }

  .notification-wrapper p {
    line-height: 1.8;
  }
`;

export const EmailForm = ({ rmsEmailAddress = '', snswEmailAddress = '' }) => {
  const Config = envConfig();
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };

  const [emailValue, setEmailValue] = useState(snswEmailAddress || '');
  const [emailSentTo, setEmailSentTo] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [saveAsPrimaryEmail, setSaveAsPrimaryEmail] = useState(false);
  const [confirmEmailModalOpen, setConfirmEmailModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setEmailValue(snswEmailAddress);
  }, [snswEmailAddress]); // eslint-disable-line

  const checkEmailDiff = (firstEmailAddress = '', sencondEmailAddress = '') => {
    const firstEmail =
      typeof firstEmailAddress === 'string'
        ? firstEmailAddress.toLowerCase()
        : '';
    const SecondEmail =
      typeof sencondEmailAddress === 'string'
        ? sencondEmailAddress.toLowerCase()
        : '';

    if (firstEmail !== SecondEmail) return true;
  };

  const updateEmailStatus = (dispatch: any, status: string) => {
    dispatch({
      type: 'get_interim_licence_end',
      payload: {
        emailSubmitStatus: status,
        unableToGenerateErrorStatus: false,
      },
    });
  };

  const errorStatus =
    globalState?.interimLicence &&
    interimLicenceErrorStatus(globalState?.interimLicence);
  const hasExpiredError = errorStatus?.code === 'INTERIM_DL_DOCUMENT_EXPIRED';

  const changeEmailAddress = (e: any) => {
    const { value } = e.target;

    if (globalState?.emailSubmitStatus !== '') {
      setSaveAsPrimaryEmail(false);
      updateEmailStatus(dispatch, '');
    }

    if (value === '') {
      setEmailErrorMessage(formMessages.email.isValid);
    } else {
      setEmailErrorMessage('');
    }

    setEmailValue(value);
    setHasEmailError(false);
  };

  const handleSubmitButton = () => {
    if (!validation.email(emailValue)) {
      setEmailErrorMessage(formMessages.email.isValid);
      setHasEmailError(true);
      return;
    }

    setConfirmEmailModalOpen(true);
  };

  const submitEmail = async () => {
    const goToLogin = async () => {
      if (Config.debug) console.log(`the config values: ${Config}`);
      UserSession.login(`${Config.loginUrl}?state=RDL`);
    };

    let url = `${Config.checklogin_endpoint}/auth/checkLogin`;
    const res = await fetch(url, {
      credentials: 'include',
      headers: {
        'x-api-key': process.env.REACT_APP_GATEWAY_PROXY_API_KEY + '',
      },
    });

    if (Config.debug) console.log('inside SendEmail', res);

    if (res.status === 200) {
      const sendEmailRequest: SendEmailRequest = {
        toEmailAddress: emailValue,
        updateEmailAddress: saveAsPrimaryEmail,
      };

      updateEmailStatus(dispatch, 'loading');

      sendEmail(sendEmailRequest)
        .then((res) => {
          if (Config.debug) console.log(`email fetch response`, res);
          if (res.status === 401) {
            goToLogin();
          } else if (res.status === 200) {
            setEmailSentTo(emailValue);
            updateEmailStatus(dispatch, 'success');

            if (saveAsPrimaryEmail) {
              setSaveAsPrimaryEmail(false);
            }
          } else {
            updateEmailStatus(dispatch, 'error');
          }
        })
        .catch((err) => {
          updateEmailStatus(dispatch, 'error');
          if (Config.debug) console.warn(err);
        });
    } else {
      goToLogin();
    }
  };

  return (
    <>
      <EmailFormWrapper data-testid='emailContainer'>
        {globalState?.emailSubmitStatus === 'success' && (
          <div className='emailNoticeWrapper' role='status'>
            <Notice success>
              <p>
                Your email has been sent to{' '}
                <strong className='h-word-break'>{emailSentTo}</strong>
              </p>
            </Notice>
          </div>
        )}

        {globalState?.emailSubmitStatus === 'error' && (
          <div className='emailNoticeWrapper' role='alert'>
            <Notice error>
              <H6 style={{ marginBottom: '0.25rem' }}>
                Sorry, we were unable to email your receipt and interim licence
              </H6>
              <p>
                Please try again, call us on{' '}
                <DisableTelOnTabletDesktop phoneNumber='13 77 88' /> or visit a{' '}
                <a
                  href='https://www.service.nsw.gov.au/service-centre'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Service Centre
                </a>
                .
              </p>
            </Notice>
          </div>
        )}

        {!hasExpiredError ? (
          <>
            <div className='iconWrapper'>
              <div className='iconWrapperEmail'>
                <Svg.Email />
              </div>
              <p>Email receipt and interim licence to this address</p>
            </div>
            <InlineWrapper className='emailInlineWrapper'>
              <FormInput
                name='email'
                label='Enter the email you wish to send your receipt and interim licence to'
                value={emailValue}
                onChange={(e: any) => changeEmailAddress(e)}
                hasError={hasEmailError}
                errorMessage={emailErrorMessage}
              />
              <Button
                className='emailSendButton'
                onClick={handleSubmitButton}
                disabled={globalState?.emailSubmitStatus === 'loading' && true}
              >
                {globalState?.emailSubmitStatus === 'loading' ? (
                  <Svg.Spinner color='#fff' /> // use #646974 after Kiama update
                ) : (
                  'Send'
                )}
              </Button>
            </InlineWrapper>
          </>
        ) : null}

        {checkEmailDiff(rmsEmailAddress, snswEmailAddress) && (
          <Spacer mt='1.5rem'>
            <Notice className='notification-wrapper'>
              <p>
                <strong>
                  Your automatic emails may be going to the wrong address
                </strong>
                <br />
                <a
                  href={Config.emailUpdateUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Update your email
                </a>{' '}
                to make sure you receive important information from us.
              </p>
            </Notice>
          </Spacer>
        )}
      </EmailFormWrapper>
      <EmailConfirmationModal
        emailAddress={emailValue}
        contentName='Interim Driver Licence'
        onCancel={() => setConfirmEmailModalOpen(false)}
        onConfirm={() => {
          setConfirmEmailModalOpen(false);
          submitEmail();
        }}
        isOpen={confirmEmailModalOpen}
      />
    </>
  );
};
