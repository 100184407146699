import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useReducer,
} from 'react';
import { InterimLicenceResponse } from 'services/api-services';
import { reducer } from './reducer';

const initialState: AppState = {
  loading: 'NOT_STARTED',
  shouldDisplayLandingPage: false
};

interface AppContext {
  globalState: AppState;
  dispatch?: Dispatch<any>;
}

export const store = createContext<AppContext>({} as AppContext);
const { Provider } = store;

const GlobalStateProvider: FunctionComponent<any> = ({ children }) => {
  const [globalState, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ globalState, dispatch }}>{children}</Provider>;
};

export default GlobalStateProvider;

export interface AppState {
  interimLicence?: InterimLicenceResponse;
  rmsCustomerId?: string;
  loading: LoadingState;
  shouldDisplayLandingPage: boolean;
  emailSubmitStatus?: string;
  unableToGenerateErrorStatus?: boolean;
}

export type LoadingState = 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED';
