import styled from "styled-components";
import { colors } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';

const ThemeStyled = styled.div`
  .iconWrapper {
    display: flex;
    margin-bottom: -0.4rem;

    .iconWrapperEmail {
      width: 24px;
      margin-right: 0.5rem;
    }

    p {
      margin: 0;
    }
  }

  .email-input {
    margin: 0.75rem 0;

    ${Breakpoint('sm')`
      margin: 0.5rem 0 0 0;
    `}
  }

  .emailNoticeWrapper {
    ${Breakpoint('sm')`
      margin: 0 0 1rem 0;
    `}
  }

  .emailInlineWrapper {
    flex-flow: column;

    ${Breakpoint('sm')`
      flex-flow: row;
    `}

    label {
      clip: rect(1px, 1px, 1px, 1px) !important;
      clip-path: inset(50%) !important;
      height: 1px !important;
      margin: -1px !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
    }

    .field-item {
      max-width: 430px;
      margin: 0.25rem 0;
    }
  }

  .emailSendButton {
    height: 3rem;
    margin: 0.25rem 0 0 0;

    ${Breakpoint('sm')`
      margin: 0.5rem 0 0 0.5rem;
    `}
  }

  .emailSendButton {
    min-width: 115px;
  }

  .printWidget {
    border: 2px solid ${colors.lightGrey2};
    border-radius: 5px;
    padding: 1.25rem 1rem;

    ${Breakpoint('sm')`
      padding: 2.5rem;
    `}

    h6 {
      margin: 0;
    }

    strong {
      margin-bottom: 0.25rem;
    }

    a {
      color: ${colors.blue};
    }
  }

  .inlineWrapperPrint {
    display: flex;
    margin: 0.5rem 0 1rem 0;

    .inlineWrapperPrintIcon {
      width: 20px;
      margin-right: 0.5rem;
    }

    p {
      margin: 0;
    }
  }
`;

export default ThemeStyled;
