import { errorMessages, inlineErrorCodes } from 'config/error-messages';
import { InterimLicenceResponse } from './api-services';
import { isMobileOnly } from 'react-device-detect';
import {UserSession} from "@rmstransactions/components";
import {envConfig} from "../config/env";

const host = envConfig().apiHost;
export function base64ToPdfBlob(base64: string) {
  console.log(`the base64 string`, base64);
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: 'application/pdf' });
}

export function interimLicenceErrorStatus(
  interimLicenceResponse: InterimLicenceResponse
): ErrorStatus | undefined {
  if (interimLicenceResponse.status !== 200) {
    return {
      ...errorMessages.filter((em) => em.code === 'SYSTEM_FAILURE')[0],
      isOnPage: false,
    };
  } else if (interimLicenceResponse.systemMessages) {
    const severity = interimLicenceResponse.systemMessages[0].severity;
    const identifier = interimLicenceResponse.systemMessages[0].identifier;
    if (severity === 'ERROR') {
      return {
        ...errorMessages.filter((em) => em.code === identifier)[0],
        isOnPage: inlineErrorCodes.filter((c) => identifier === c)?.length > 0,
      };
    }
  }
  return;
}

export interface ErrorStatus {
  code: string;
  message: string;
  isOnPage: boolean;
  header?: string;
}

/**
 * Possible error codes
 *
 * UNABLE_GENERATE_INTERIM_LIC_PDF - landing page
 * INTERIM_DL_DOCUMENT_EXPIRED - landing page
 * SYSTEM_FAILURE - general errpr
 * INVALID_INPUT - technical error
 * INVALID_MSG_SOLN - technical error
 * SCHEMA_VALIDATION_ERROR - technical error
 */
export const handleRouteToErrorPage = (
  dispatch: any,
  history: any,
  errorCode: string
) => {
  const [filteredErrorMessage] = errorMessages.filter(
    (error) => error.code === errorCode
  );
  dispatch({
    type: 'get_interim_licence_end',
    payload: {
      interimLicence: {
        errorMessage: isMobileOnly
          ? filteredErrorMessage.messageMobile
          : filteredErrorMessage.message,
      },
      isOnPage: false,
    },
  });
  history.push('/error');
};

export const silentAuthUrl = `${host}/silentLogin?state=RDL`;
export const silentLogin = () => {
  console.log('silent Login url --->' + silentAuthUrl);
  UserSession.silentLogin(silentAuthUrl)
};
