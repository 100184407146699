export interface AppConfig {
  apiHost: string;
  debug: Bool;
  loginUrl: string;
  emailUpdateUrl: string;
  checklogin_endpoint: string;
  tutd: TUTD;
  globalNavEnv: GlobalNavEnv;
  mockTest: Bool;
}

export const TRANSACTION_STATE = 'RDL';
export const DRUPAL_RDL_URL =
  'https://www.service.nsw.gov.au/transaction/reprint-nsw-driver-licence-receipt';

export type GlobalNavEnv = 'prod' | 'staging' | 'it1';
export type Bool = true | false;
export type TUTD = 'production' | 'uat';

// Order by priority
const configMap: ConfigMap = {
  local: {
    apiHost: 'http://localhost:8080',
    loginUrl: 'http://localhost:8080/login',
    emailUpdateUrl:
      'https://myservicensw-account-ui.sit.my.testservicensw.net/manage/personal-details/update-email-for-tfnsw',
    checklogin_endpoint: 'http://localhost:8080',
    tutd: 'uat',
    globalNavEnv: 'it1',
    mockTest: false,
    debug: true,
  },
  it1: {
    apiHost: 'https://api-it1.g.testservicensw.net/v1/rms/gateway-proxy',
    loginUrl: 'https://api-it1.g.testservicensw.net/v1/rms/gateway-proxy/login',
    emailUpdateUrl:
      'https://myservicensw-account-ui.it1.my.testservicensw.net/manage/personal-details/update-email-for-tfnsw',
    checklogin_endpoint:
      'https://api-it1.g.testservicensw.net/v1/rms/gateway-proxy',
    tutd: 'uat',
    globalNavEnv: 'it1',
    mockTest: false,
    debug: true,
  },
  it3: {
    apiHost: 'https://api-it3.g.testservicensw.net/v1/rms/gateway-proxy',
    loginUrl: 'https://api-it3.g.testservicensw.net/v1/rms/gateway-proxy/login',
    emailUpdateUrl:
      'https://myservicensw-account-ui.it3.my.testservicensw.net/manage/personal-details/update-email-for-tfnsw',
    checklogin_endpoint:
      'https://api-it3.g.testservicensw.net/v1/rms/gateway-proxy',
    tutd: 'uat',
    globalNavEnv: 'staging',
    mockTest: false,
    debug: true,
  },
  it4: {
    apiHost: 'https://api-it4.g.testservicensw.net/v1/rms/gateway-proxy',
    loginUrl: 'https://api-it4.g.testservicensw.net/v1/rms/gateway-proxy/login',
    emailUpdateUrl:
      'https://myservicensw-account-ui.it4.my.testservicensw.net/manage/personal-details/update-email-for-tfnsw',
    checklogin_endpoint:
      'https://api-it4.g.testservicensw.net/v1/rms/gateway-proxy',
    tutd: 'uat',
    globalNavEnv: 'staging',
    mockTest: false,
    debug: true,
  },
  load: {
    apiHost: 'https://api-lt.g.testservicensw.net/v1/rms/gateway-proxy',
    loginUrl: 'https://api-lt.g.testservicensw.net/v1/rms/gateway-proxy/login',
    emailUpdateUrl:
      'https://myservicensw-account-ui.sit.my.testservicensw.net/manage/personal-details/update-email-for-tfnsw',
    checklogin_endpoint: '',
    tutd: 'uat',
    globalNavEnv: 'staging',
    mockTest: false,
    debug: true,
  },
  psm: {
    apiHost: 'https://api-psm.g.testservicensw.net/v1/rms/gateway-proxy',
    loginUrl: 'https://api-psm.g.testservicensw.net/v1/rms/gateway-proxy/login',
    emailUpdateUrl:
      'https://myservicensw-account-ui.staging.my.testservicensw.net/manage/personal-details/update-email-for-tfnsw',
    checklogin_endpoint:
      'https://api-psm.g.testservicensw.net/v1/rms/gateway-proxy',
    tutd: 'uat',
    globalNavEnv: 'staging',
    mockTest: false,
    debug: true,
  },
  prod: {
    apiHost: 'https://api.g.service.nsw.gov.au/v1/rms/gateway-proxy',
    loginUrl: 'https://api.g.service.nsw.gov.au/v1/rms/gateway-proxy/login',
    emailUpdateUrl:
      'https://account.service.nsw.gov.au/manage/personal-details/update-email-for-tfnsw',
    checklogin_endpoint:
      'https://api.g.service.nsw.gov.au/v1/rms/gateway-proxy',

    tutd: 'production',
    globalNavEnv: 'prod',
    mockTest: false,
    debug: true,
  },
};

const envNames = {
  local: `localhost`,
  it1: 'reprint-interim-licence-it1.apps.pcf-ext.testservicensw.net',
  it3: 'reprint-interim-licence-it3.apps.pcf-ext.testservicensw.net',
  it4: 'reprint-interim-licence-it4.apps.pcf-ext.testservicensw.net',
  load: 'reprint-driver-licence-receipt-ui-load.apps.pcf-ext.testservicensw.net',
  psm: 'reprint-interim-licence.testservicensw.net',
  prod: 'reprint-interim-licence.service.nsw.gov.au',
} as { [index: string]: string };

const getEnv = (): string => {
  const documentUrl = document.URL.toString();
  const envDomain = documentUrl.split(`//`)[1].split('/')[0].split(':')[0];
  const envRecord = Object.keys(envNames)
    .map((key: string) => [key, envNames[key]])
    .filter((entry) => entry[1] === envDomain);
  // console.log(`app url ${documentUrl}`);
  // console.log(envDomain);
  if (!envRecord) console.warn(`No environment found for ${envDomain}`);
  return envRecord[0][0];
};

export const envConfig = (): AppConfig => {
  const env = getEnv();
  return configMap[env];
};

export interface ConfigMap {
  [index: string]: AppConfig;
}
