import React from 'react'
import { H2 } from '@snsw/react-component-library';
import { helpers } from '@rmstransactions/components';
import styled from 'styled-components';

const TxnDetailsWrapper = styled.div`
  margin-bottom: 3rem;

  .txnDetailsTitle {
    margin-bottom: 0.75rem;
  }

  .inlineWrapper {
    margin-bottom: 0.5rem;
  }
`;

export interface TransactionDetailsProps {
    receiptNo: string;
    txnDate: string,
    lastName: string,
    licNo: string
};

export const TxnDetails = ({
  receiptNo,
  txnDate,
  lastName,
  licNo
}: TransactionDetailsProps) => (
  <TxnDetailsWrapper data-testid='txnDetails'>
    <H2 className='txnDetailsTitle'>Summary</H2>
    <div className='inlineWrapper'>
      <span>Receipt number: </span>
      <span>{receiptNo}</span>
    </div>
    <div className='inlineWrapper'>
      <span>Date of transaction: </span>
      <span>{helpers.dateFormat(txnDate, 'D MMMM YYYY')}</span>
    </div>
    <div className='inlineWrapper'>
      <span>Last name: </span>
      <span>{lastName}</span>
    </div>
    <div className='inlineWrapper'>
      <span>NSW Driver Licence number: </span>
      <span>{licNo}</span>
    </div>
  </TxnDetailsWrapper>
);

