import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Notice, colors } from '@snsw/react-component-library';
import { FormWrapper, Header, Container, Spacer } from '@rmstransactions/components';
import { InlineWrapper } from 'components/Styled/InlineWrapper';
import { AppState, store } from 'provider/GlobalStateProvider';
import { interimLicenceErrorStatus } from 'services/common-service';
import { useHistory } from 'react-router';

const ErrorWrapper = styled.div`
  a {
    color: ${colors.blue};
  }
`;

export const ErrorPage: React.FC = () => {
  const [ errorMsg, setErrorMsg ] = useState('');
  const { globalState } = useContext(store) as { globalState: AppState };
  const { interimLicence } = globalState;
  const history = useHistory();

  useEffect(() => {
    const errorStatus = (globalState.interimLicence && interimLicenceErrorStatus(globalState.interimLicence));
    setErrorMsg(interimLicence?.errorMessage || '');
    if (!errorStatus) history.push('/');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorWrapper>
      <Header title={`We're sorry about this...`} />
      <Container>
        <FormWrapper maxWidth='770px'>
          <div role='alert'>
            <Notice error>
              <p dangerouslySetInnerHTML={{ __html: errorMsg }} />
            </Notice>
          </div>
          <Spacer mb={2} />
          <InlineWrapper>
            <Button
              theme='secondary'
              href='https://www.service.nsw.gov.au/transaction/reprint-nsw-driver-licence-receipt'
            >
              Quit
            </Button>
          </InlineWrapper>
        </FormWrapper>
      </Container>
    </ErrorWrapper>
  );
};
