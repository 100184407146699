import {helpers, UserSession} from '@rmstransactions/components';
import { envConfig } from 'config/env';
import { forceLogout } from 'services/api-services';

const Config = envConfig();

export const goToLogin = async () => {
  if (Config.debug) console.log(`the config values: ${Config}`);
  UserSession.login(`${Config.loginUrl}?state=RDL`)
};

export const sessionTimer = setTimeout(() => {
  forceLogout().finally(() => {
    goToLogin();
  });
}, helpers.sessionTime);
