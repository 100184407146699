import styled from "styled-components";

export const InlineWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;

  .checkbox-item {
    margin-top: 0.65rem;
  }
`;
