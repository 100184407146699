import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AppState, store } from 'provider/GlobalStateProvider';
import {
  ComponentLoader,
  H6,
  Notice,
  colors,
} from '@snsw/react-component-library';
import {
  Container,
  ErrorService,
  FormWrapper,
  Header,
  Quickfeed,
  UserSession,
} from '@rmstransactions/components';
import {
  PrintOrEmailLicence,
  DownloadDigitalLicence,
  TxnDetails,
} from 'components';
import {
  getInterimLicencePdf,
  interimLicenceUrl,
  interceptors,
} from 'services/api-services';
import { useHistory } from 'react-router';
import { handleRouteToErrorPage, silentLogin } from 'services/common-service';
import fetchData from 'utils/mockData/success'; // NOTE: must be success for e2e test.
import { UserToken } from '@rmstransactions/components';
import { DRUPAL_RDL_URL, TRANSACTION_STATE, envConfig } from 'config/env';

const LandingWrapper = styled.div`
  .noticeWrapper {
    margin: 1rem 0 2rem 0;
  }

  .noticeTitle {
    margin-bottom: 0.75rem;
  }

  .noticeLink {
    color: ${colors.blue};
  }

  .separator {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 1.25rem 0;

    span {
      font-size: 1.15rem;
      font-weight: 700;
    }
  }
`;

export const LandingPage = () => {
  const history = useHistory(),
    Config = envConfig();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isLoginRequired = params.get('isLoginRequired');

  if (Config.debug) console.log(`landing page rendered`);
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };

  const { interimLicence, loading } = globalState;
  var { shouldDisplayLandingPage } = globalState;

  const routeToErrorPageIfNecessary = (
    errorCode: string = 'SYSTEM_FAILURE'
  ) => {
    handleRouteToErrorPage(dispatch, history, errorCode);
  };

  const loadLicence = async () => {
    getInterimLicencePdf(interimLicenceUrl)
      .then((res: any) => {
        if (Config.debug) console.log(`loadLicence fetch success`, res);
        if (Config.debug) console.log(`loadLicence fetch headers`, res.headers);
        const status = res.status;
        const statusCheck = (type: number) => {
          switch (type) {
            case 200:
              UserToken.setValue(res.token);
              // NOTE: Business error
              if (
                [
                  'INVALID_INPUT',
                  'INVALID_MSG_SOLN',
                  'SYSTEM_FAILURE',
                  'SCHEMA_VALIDATION_ERROR',
                ].includes(res.systemMessages[0].identifier)
              ) {
                routeToErrorPageIfNecessary(res.systemMessages[0].identifier);
              }
              dispatch({
                type: 'get_interim_licence_end',
                payload: {
                  interimLicence: res,
                },
              });
              return;
            default:
              // General server error
              routeToErrorPageIfNecessary();
              return;
          }
        };
        statusCheck(status);
      })
      .catch((err) => {
        if (Config.debug) console.warn(`loadLicence fetch error`, err);

        // NOTE: Mock test failure case
        if (Config.mockTest) {
          if (fetchData.status === 200) {
            if (
              [
                'INVALID_INPUT',
                'INVALID_MSG_SOLN',
                'SYSTEM_FAILURE',
                'SCHEMA_VALIDATION_ERROR',
              ].includes(fetchData.systemMessages[0].identifier)
            ) {
              routeToErrorPageIfNecessary(
                fetchData.systemMessages[0].identifier
              );
            }
            dispatch({
              type: 'get_interim_licence_end',
              payload: {
                interimLicence: fetchData,
              },
            });
            return;
          }
        }
        // Show general error
        routeToErrorPageIfNecessary();
      });
  };

  useEffect(() => {
    interceptors(
      history,
      envConfig().apiHost,
      TRANSACTION_STATE,
      DRUPAL_RDL_URL
    );
    if (UserSession.isSilentLoginCalled()) {
      UserSession.resetSilentLoginCalled();
      if (isLoginRequired === 'true') {
        let error = { message: '401 Unauthorized' };
        ErrorService.handleAPIErrorForAuthTrans(
          error,
          history,
          envConfig().apiHost,
          TRANSACTION_STATE,
          DRUPAL_RDL_URL
        );
      } else if (isLoginRequired === 'false') {
        sessionStorage.setItem('activeSession', 'yes');
        dispatch({ type: 'update_should_display_landing_page' });
        dispatch({ type: 'get_interim_licence_start' });
        loadLicence();
      }
    } else {
      UserSession.setSilentLoginCalled();
      silentLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (Config.debug) console.log(`globalState`, globalState);

  return (
    <>
      {!shouldDisplayLandingPage ? (
        <ComponentLoader fullPage={true} label="One moment please..." />
      ) : (
        <LandingWrapper>
          <ComponentLoader
            active={loading === 'IN_PROGRESS'}
            fullPage={true}
            label="One moment please..."
          />
          <Header
            title={`Reprint your Driver Licence Receipt \n(interim licence)`}
          />
          <Container>
            <FormWrapper maxWidth="770px">
              <div className="noticeWrapper" data-testid="noticeContainer">
                <Notice warning>
                  <H6 className="noticeTitle">
                    To legally drive, please carry your Driver Licence Receipt
                    (interim licence),
                    <br />
                    new driver licence card, or{' '}
                    <a
                      className="noticeLink"
                      href="https://www.service.nsw.gov.au/campaign/nsw-digital-driver-licence"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Digital Driver Licence
                    </a>
                  </H6>
                  <p>
                    Your interim licence is valid for two months from the issue
                    date.
                  </p>
                </Notice>
              </div>
              <PrintOrEmailLicence />
              <div className="separator">
                <span>- OR -</span>
              </div>
              <DownloadDigitalLicence />
              {interimLicence?.txnDetails ? (
                <TxnDetails
                  data-testid="transactionDetails"
                  licNo={interimLicence?.txnDetails.licNo}
                  receiptNo={interimLicence?.txnDetails.receiptNo}
                  lastName={interimLicence?.txnDetails.lastName}
                  txnDate={interimLicence?.txnDetails.txnDate}
                />
              ) : null}
              <Quickfeed
                sourceApplication="reprint-driver-licence"
                label="How was your experience?"
                environment={Config.tutd}
              />
            </FormWrapper>
          </Container>
        </LandingWrapper>
      )}
      );
    </>
  );
};
