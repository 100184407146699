import { AppState } from './GlobalStateProvider';

export const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case 'get_interim_licence_start':
      return {
        ...state,
        loading: 'IN_PROGRESS',
      };
    case 'update_should_display_landing_page':
      return {
        ...state,
        shouldDisplayLandingPage: true
      };
    case 'get_interim_licence_end':
      return {
        ...state,
        ...action.payload,
        loading: 'COMPLETED',
      };
    default:
      throw new Error(`Invalid action`);
  }
};
