import { envConfig } from 'config/env';
import axios from 'axios';
import {ErrorService, HttpRequestHandler, UserToken} from '@rmstransactions/components';
const DEBUG = process.env.REACT_APP_DEBUG;

const host = envConfig().apiHost;
HttpRequestHandler.setCommonApiKeyHeader()
export const interimLicenceUrl = `${host}/auth/getInterimLicence`;

export const getInterimLicencePdf = async (
  url: string
): Promise<InterimLicenceResponse> => {
  if (DEBUG) console.log('URL------->', url);
  let config: RequestInit = {
    credentials: 'include'
  };

  let isPrintUrl:boolean = false;
  if(url.indexOf("/print") > -1){
    isPrintUrl = true
  }
  if(isPrintUrl){
    config = {
      credentials: 'include',
      headers: { 'x-token': UserToken.getValue(),  'x-api-key': process.env.REACT_APP_GATEWAY_PROXY_API_KEY + ''},
    }
  } else {
    config = {
      credentials: 'include',
      headers: { 'x-api-key': process.env.REACT_APP_GATEWAY_PROXY_API_KEY + ''},
    }
  }

  const res = await fetch(HttpRequestHandler.appendApiKeyAsParam(url), config);
  let result = {} as InterimLicenceResponse;

  if (res.status !== 401) {
    let jsonRes = (await res.json())
    result = jsonRes as InterimLicenceResponse;
    let token  = res.headers.get('x-token')
    if(!isPrintUrl && token != null){
      result.token = token
    }
  }
  result.status = res.status;
  result.statusText = res.statusText;

  const successResponseFromDrives =
    result.systemMessages && result.systemMessages[0]['severity'] === 'SUCCESS';

  successResponseFromDrives &&
    (result.txnDetails = {
      licNo: result.licenceId,
      lastName: result.customerName?.lastName,
      receiptNo: result.transactionId,
      txnDate: result.txnDate,
    });
  return result;
};

export const sendEmail = async (request: SendEmailRequest): Promise<any> => {
  const url = `${host}/auth/emailInterimLicence`;
  const res = await axios.post(url, request, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
  return res.data;
};

export const forceLogout = async (): Promise<any> => {
  const res = await fetch(`${host}/logout`, {
    credentials: 'include',
    headers: { 'x-token': UserToken.getValue() },
  });
  return res;
};
export interface InterimLicenceResponse {
  rmsCustomerId: string;
  licenceId: string;
  licence: string;
  customerName: any; //TODO define CustomerName type
  txnDetails?: {
    licNo: string;
    receiptNo: string;
    lastName: string;
    txnDate: string;
  };
  systemMessages: any; //TODO define SystemMessages type
  txnDate: string;
  transactionId: string;
  customerEmails: {
    rmsEmailAddress: string;
    snswEmailAddress: string;
  };
  status: number;
  statusText: string;
  token?: string;
  error?: string;
  message?: string;
  errorMessage: string;
}

export interface SendEmailRequest {
  toEmailAddress: String;
  updateEmailAddress: boolean;
}

export const interceptors = (history: any, apiHost: any, transactionState: any, drupalTransactionLandingPageURL: any) => {
  HttpRequestHandler.setCommonContentType('application/json')

  axios.interceptors.request.use((x: any) => {
    return x;
  });

  axios.interceptors.response.use(async (x: any) => {
    return x;
  }, (y: any) => {

    ErrorService.handleAPIErrorForAuthTrans(y, history, apiHost, transactionState, drupalTransactionLandingPageURL);
  });
}
