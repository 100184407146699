import React from 'react';
import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { Button, FormInput } from '@snsw/react-component-library';
import {Container, FormWrapper, Header, UserSession} from '@rmstransactions/components';
import { useState } from 'react';
import { envConfig } from 'config/env';

interface LoginProps {
    userId: String;
    password: String;
}

const Config = envConfig();

const LoginFormWrapper = styled.div`
  input[type="button"] {
    margin: 0;
  }

  .emailSendButton {
    height: 3rem;
    margin: 0.25rem 0 0 0;

    ${Breakpoint('sm')`
      margin: 1rem 0 0 0.5rem;
    `}
  }

  .inlineWrapper {
    flex-flow: column;

    ${Breakpoint('sm')`
      flex-flow: row;
    `}
  }

  .loginButton {
    height: 3rem;

    ${Breakpoint('sm')`
      margin: 1rem 0 0 0.5rem;
    `}
  }
`;
// This is only used for local testing and load testing to provide login functionality so that we can test with different customerIds

export const LoginPage: React.FC<LoginProps> = (props: LoginProps) => {
    const [userid, setUserId] = useState("user");
    const updateUserId = (evt: any) => {
        setUserId(evt.target.value);
    }
    const login = () => {
      if (Config.debug) console.log(`logging in with userid ${userid}`);
      UserSession.login(`${Config.loginUrl}?state=RDL&customerId=${userid}`)
    }
    return (
        <LoginFormWrapper>
            <Header>Login</Header>
            <Container>
                <FormWrapper maxWidth='550px'>
                        <FormInput
                            className="classname"
                            disabled={false}
                            errorMessage="FormInput error message"
                            hasError={false}
                            id="fieldID"
                            inputWidth="md"
                            label="Login"
                            mask={null}
                            maxLength={50}
                            name="userid"
                            onChange={updateUserId}
                            placeholder="Customerid .. 1235, 1236 or please ask the devs for test customer ids"
                            type="text"
                        />
                        <Button className='loginButton' onClick={login}>Login</Button>
                </FormWrapper>
            </Container>
        </LoginFormWrapper>
    )
}
