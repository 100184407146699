export const errorMessages: Array<ErrorMessage>  = [
  {
    code: 'UNABLE_GENERATE_INTERIM_LIC_PDF',
    message: `<h2>Sorry, we couldn’t generate your Driver Licence Receipt (interim licence) at this time</h2>Please try again, call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>. Alternatively, your <a href="https://www.service.nsw.gov.au/campaign/nsw-digital-driver-licence" target="_blank" rel="noopener noreferrer">NSW Digital Driver Licence</a> is available through the Service NSW app.`,
    messageMobile: `<h2>Sorry, we couldn’t generate your Driver Licence Receipt (interim licence) at this time</h2><br>Please try again, call us on  <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>. Alternatively, your <a href="https://www.service.nsw.gov.au/campaign/service-nsw-mobile-app" target="_blank" rel="noopener noreferrer">NSW Digital Driver Licence</a> is available through the Service NSW app.`
  },
  {
    code: 'INTERIM_DL_DOCUMENT_EXPIRED',
    message: `<h2>It looks like there is no current Driver Licence Receipt (interim licence) on record for you</h2>If your driver licence card has not yet arrived, please call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>. Alternatively, your <a href="https://www.service.nsw.gov.au/campaign/nsw-digital-driver-licence" target="_blank" rel="noopener noreferrer">NSW Digital Driver Licence</a> is available through the Service NSW app.`,
    messageMobile: `<h2>It looks like there is no current Driver Licence Receipt (interim licence) on record for you</h2>If your driver licence card has not yet arrived, please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>. Alternatively, your <a href="https://www.service.nsw.gov.au/campaign/service-nsw-mobile-app" target="_blank" rel="noopener noreferrer">NSW Digital Driver Licence</a> is available through the Service NSW app.`
  },
  {
    code: 'SYSTEM_FAILURE',
    message: `Our system is temporarily unavailable, we apologise for any inconvenience. You can try again later, <a href="https://www.service.nsw.gov.au/contact-us" target="_blank" rel="noopener noreferrer">contact us</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.`,
    messageMobile: `Our system is temporarily unavailable, we apologise for any inconvenience. You can try again later, <a href="https://www.service.nsw.gov.au/contact-us" target="_blank" rel="noopener noreferrer">contact us</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.`,
  },
  // Technical Errors
  {
    code: 'INVALID_INPUT',
    message: 'There was a technical error. You can try again later, call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.',
    messageMobile: 'There was a technical error. You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.'
  },
  {
    code: 'INVALID_MSG_SOLN',
    message: `There was a technical error. You can try again later, call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.`,
    messageMobile: `There was a technical error. You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.`,
  },
  {
    code: 'SCHEMA_VALIDATION_ERROR',
    message: `There was a technical error. You can try again later, call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.`,
    messageMobile: `There was a technical error. You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">Service Centre</a>.`,
  }
]

export const inlineErrorCodes = ['INTERIM_DL_DOCUMENT_EXPIRED', 'UNABLE_GENERATE_INTERIM_LIC_PDF'];

export interface ErrorMessage {
  code: string;
  message: string;
  messageMobile?: string;
  header?: string;
}
