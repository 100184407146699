import React from 'react';
import { Button, colors } from '@snsw/react-component-library';
import { Svg } from '@rmstransactions/components';
import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';

const DownloadComponentContainer = styled.div`
  border: 2px solid ${colors.lightGrey2};
  border-radius: 5px;
  margin-bottom: 2.5rem;
  padding: 1.25rem 1rem;

  ${Breakpoint('sm')`
    padding: 2.5rem;
  `}

  .inlineWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 2rem;

    ${Breakpoint('xs')`
      flex-flow: row;
    `}

    svg {
      margin: 0 0 0.75rem 0;

      ${Breakpoint('xs')`
        margin: 0 1.5rem 0 0;
      `}
    }

    .download-component__btn {
      width: 100%;

      ${Breakpoint('xs')`
        width: 310px;
      `}

      > svg {
        display: none;
      }
    }
  }

  svg {
    margin: 0 1.25rem 0 0;
  }

  ul {
    line-height: 0;
    list-style-type: disc;
    padding-left: 1rem;
    margin-bottom: 0;
  }

  li {
    line-height: 1.4;
    padding-left: 0.5rem;
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin: 0;
    }
  }
`;

export const DownloadDigitalLicence = () => (
  <DownloadComponentContainer>
    <div className='inlineWrapper'>
      <Svg.Phone width='48px' height='48px' />
      <Button
        aria-label="External link - Download Service NSW App"
        className='download-component__btn'
        href='https://www.service.nsw.gov.au/campaign/service-nsw-mobile-app'
        external
      >
        Download digital licence
      </Button>
    </div>
    <ul>
      <li>Download or update the Service NSW app on your mobile device</li>
      <li>Keep your digital driver licence with you while driving</li>
      <li>
        The NSW Digital Driver Licence is only valid within NSW.
        Carry your interim licence or plastic card while driving interstate or overseas.
      </li>
    </ul>
  </DownloadComponentContainer>
);

