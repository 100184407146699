import React, { useState, useContext } from 'react';
import { AppState, store } from 'provider/GlobalStateProvider';
import { H2, Button, Notice } from '@snsw/react-component-library';
import {Svg, Spacer, UserSession, HttpRequestHandler} from '@rmstransactions/components';
import {
  base64ToPdfBlob,
  handleRouteToErrorPage,
  interimLicenceErrorStatus,
} from 'services/common-service';
import { EmailForm } from './EmailForm';
import styled from 'styled-components';
import { errorMessages } from 'config/error-messages';
import { envConfig } from 'config/env';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { isMobileOnly } from 'react-device-detect';
import fetchData from 'utils/mockData/success'; // NOTE: must be success for e2e test.
import { getInterimLicencePdf, interimLicenceUrl } from 'services/api-services';
import { useHistory } from 'react-router';

const PrintContainer = styled.div`
  padding-top: 1rem;
  width: 100%;

  label {
    white-space: break-spaces;

    &:before {
      top: 10px;
    }
  }

  h2 {
    margin-bottom: 1.25rem;
  }

  .inlineWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 1rem;

    ${Breakpoint('xs')`
      flex-flow: row;
      margin-bottom: 2rem;
    `}

    svg {
      margin: 0 0 0.75rem 0;

      ${Breakpoint('xs')`
        margin: 0 1.5rem 0 0;
      `}
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;

      ${Breakpoint('xs')`
        width: 310px;
      `}
    }
  }
`;

const Config = envConfig();

export const PrintOrEmailLicence: React.FC = () => {
  const { globalState, dispatch } = useContext(store) as {
    globalState: AppState;
    dispatch: any;
  };
  const { interimLicence, unableToGenerateErrorStatus } = globalState;
  const [downloadStatus, setDownloadStatus] = useState('');
  const history = useHistory();

  if (Config.debug) console.log('Global state', globalState);

  const txnDetails = interimLicence?.txnDetails;
  const errorStatus =
    interimLicence && interimLicenceErrorStatus(interimLicence);
  const [unableGenerateErrorMessage] =
    errorMessages.filter(
      (err) => err.code === 'UNABLE_GENERATE_INTERIM_LIC_PDF'
    ) || '';
  const [ExpiredMessage] =
    errorMessages.filter((err) => err.code === 'INTERIM_DL_DOCUMENT_EXPIRED') ||
    '';
  const responsiveUnableGenerateErrorMessage = isMobileOnly
    ? unableGenerateErrorMessage?.messageMobile
    : unableGenerateErrorMessage?.message;
  const responsiveExpiredErrorMessage = isMobileOnly
    ? ExpiredMessage?.messageMobile
    : ExpiredMessage?.message;

  const hasUnableGenerateError =
    errorStatus?.code === 'UNABLE_GENERATE_INTERIM_LIC_PDF';
  const hasExpiredError = errorStatus?.code === 'INTERIM_DL_DOCUMENT_EXPIRED';

  const routeToErrorPageIfNecessary = (
    errorCode: string = 'SYSTEM_FAILURE'
  ) => {
    handleRouteToErrorPage(dispatch, history, errorCode);
  };

  const handleDownloadDigitalLicence = async (e: any) => {
    e.preventDefault();

    const goToLogin = async () => {
      if (Config.debug) console.log(`the config values: ${Config}`);
      UserSession.login(`${Config.loginUrl}?state=RDL`)
    };

    const handleFetchEvent = async () => {
      let url = HttpRequestHandler.appendApiKeyAsParam(`${Config.checklogin_endpoint}/auth/checkLogin`);
      const res = await fetch(url, {
        credentials: 'include',
        headers: { 'x-api-key': process.env.REACT_APP_GATEWAY_PROXY_API_KEY + ''},
      });

      if (Config.debug) console.log('inside handleEvent', res);
      if (res.status === 200) {
        getInterimLicencePdf(`${interimLicenceUrl}/print`)
          .then((res) => {
            if (Config.debug) console.log(`loadLicence fetch success`, res);
            const status = res.status;
            const statusCheck = (type: number) => {
              switch (type) {
                case 401:
                  goToLogin();
                  return;
                case 200:
                  // NOTE: Business error
                  if (
                    [
                      'INVALID_INPUT',
                      'INVALID_MSG_SOLN',
                      'SYSTEM_FAILURE',
                      'SCHEMA_VALIDATION_ERROR',
                    ].includes(res.systemMessages[0].identifier)
                  ) {
                    setDownloadStatus('');
                    routeToErrorPageIfNecessary(res.systemMessages[0].identifier);
                  } else if (res.licence) {
                    dispatch({
                      type: 'get_interim_licence_end',
                      payload: {
                        interimLicence: res,
                        emailSubmitStatus: '',
                      },
                    });
                    // Pdf Generation
                    setDownloadStatus('success');
                    const file = base64ToPdfBlob(res?.licence!!);
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  } else {
                    dispatch({
                      type: 'get_interim_licence_end',
                      payload: {
                        interimLicence: res,
                        emailSubmitStatus: '',
                        unableToGenerateErrorStatus: true,
                      },
                    });
                    setDownloadStatus('');
                  }
                  return;
                default:
                  // General server error
                  routeToErrorPageIfNecessary();
                  return;
              }
            };
            statusCheck(status);
          })
          .catch((err) => {
            if (Config.debug) console.warn(`loadLicence fetch error`, err);
            // NOTE: Mock test failure case
            if (Config.mockTest) {
              if (fetchData.status === 200) {
                if (
                  [
                    'INVALID_INPUT',
                    'INVALID_MSG_SOLN',
                    'SYSTEM_FAILURE',
                    'SCHEMA_VALIDATION_ERROR',
                  ].includes(fetchData.systemMessages[0].identifier)
                ) {
                  routeToErrorPageIfNecessary(
                    fetchData.systemMessages[0].identifier
                  );
                }
                return;
              }
            }
            // Show general error
            routeToErrorPageIfNecessary();
          });
      }
      else {
        goToLogin()
      }
    };
    // throttling
    setDownloadStatus('loading');
    setTimeout(() => {
      handleFetchEvent();
    }, 300);
  };

  return (
    <>
      <PrintContainer data-testid="printContainer">
        <H2
          aria-label={
            'Please choose one of the following options: Print interim licence or Download Digital Licence'
          }
        >
          Please choose one of the following:
        </H2>
        <div className="printWidget">
          {hasUnableGenerateError && (
            <>
              {unableToGenerateErrorStatus && (
                <Spacer mt={-1} mb={2} role="alert">
                  <Notice error>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: responsiveUnableGenerateErrorMessage || '',
                      }}
                    />
                  </Notice>
                </Spacer>
              )}
              <div className="inlineWrapper">
                <Svg.Print width="48px" height="48px" />
                <Button
                  onClick={handleDownloadDigitalLicence}
                //disabled={errorStatus?.code === 'UNABLE_GENERATE_INTERIM_LIC_PDF'}
                >
                  {downloadStatus === 'loading' ? (
                    <Svg.Spinner color="#fff" />
                  ) : (
                      'Print interim licence'
                    )}
                </Button>
              </div>
              <p>
                <strong>You must print and sign</strong> your interim paper
                licence and <strong>carry</strong> it while driving
              </p>
              <EmailForm
                rmsEmailAddress={
                  interimLicence?.customerEmails?.rmsEmailAddress
                }
                snswEmailAddress={
                  interimLicence?.customerEmails?.snswEmailAddress
                }
              />
            </>
          )}
          {hasExpiredError && (
            <>
              <Spacer mt={-1} mb={2} role="alert">
                <Notice error>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: responsiveExpiredErrorMessage || '',
                    }}
                  />
                </Notice>
              </Spacer>
              <div className="inlineWrapper" style={{ marginBottom: '0' }}>
                <Svg.Print width="48px" height="48px" />
                <p>
                  <strong>You must print and sign</strong> your interim paper
                  licence and <strong>carry</strong> it while driving
                </p>
              </div>
            </>
          )}

          {txnDetails ? (
            <>
              <div className="inlineWrapper">
                <Svg.Print width="48px" height="48px" />
                <Button
                  onClick={handleDownloadDigitalLicence}
                  disabled={downloadStatus === 'loading'}
                >
                  {downloadStatus === 'loading' ? (
                    <Svg.Spinner color="#fff" />
                  ) : (
                      'Print interim licence'
                    )}
                </Button>
              </div>
              <p>
                <strong>You must print and sign</strong> your interim paper
                licence and <strong>carry</strong> it while driving
              </p>
              <EmailForm
                rmsEmailAddress={
                  interimLicence?.customerEmails?.rmsEmailAddress
                }
                snswEmailAddress={
                  interimLicence?.customerEmails?.snswEmailAddress
                }
              />
            </>
          ) : null}
        </div>
      </PrintContainer>
    </>
  );
};
