import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import { theme, GlobalStyle } from '@snsw/react-component-library';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { LandingPage, ErrorPage, NotFound } from 'pages';
import { LoginPage } from 'pages/LoginPage';
import { envConfig } from 'config/env';
import { goToLogin, sessionTimer } from 'utils/helpers';
import { forceLogout } from 'services/api-services';
import GlobalStateProvider from 'provider/GlobalStateProvider';
import ThemeStyled from 'components/Styled/ThemeStyled';
import * as serviceWorker from 'serviceWorker';
import {
  RMSGlobalStyle,
  MyAccountNav,
  MyAccountNavConfig,
  helpers,
  LoggedOutErrorPage,
} from '@rmstransactions/components';
import { GlobalNav } from '@snsw/react-global-nav';

const Config = envConfig();
MyAccountNavConfig.setupGlobalNavUri(Config.globalNavEnv);
MyAccountNavConfig.setLoggedInUser();

function Root() {
  const history = createBrowserHistory();

  useEffect(() => {
    let timerId: any = sessionTimer;
    const resetTimer = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        forceLogout().finally(() => {
          goToLogin();
        });
      }, helpers.sessionTime);
    };

    document.body.addEventListener('click', resetTimer);

    return () => {
      clearTimeout(timerId);
      window.removeEventListener('click', resetTimer);
    };
  }, []); // eslint-disable-line

  const html = (
    <ThemeStyled>
      <RMSGlobalStyle />
      <GlobalNav accountNavLinks={<MyAccountNav />}>
        <GlobalStateProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Router history={history}>
              <Switch>
                {/**
                 * TODO: Login route below only for local and load testing to
                 * simulate login with different customerids, add a condition check later
                 */}
                <Route exact path={['/login']} component={LoginPage} />
                <Route exact path={['/']} component={LandingPage} />
                <Route
                  exact
                  path={['/error/logged-out']}
                  component={LoggedOutErrorPage}
                />
                <Route exact path={['/error']} component={ErrorPage} />
                <Route
                  exact
                  path={['/servicensw-app-download']}
                  component={() => {
                    window.location.href = `https://www.service.nsw.gov.au/campaign/service-nsw-mobile-app`;
                    return null;
                  }}
                />
                <Route path='*' component={NotFound} />
              </Switch>
            </Router>
          </ThemeProvider>
        </GlobalStateProvider>
      </GlobalNav>
    </ThemeStyled>
  );

  return html;
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
